<script lang="ts">
  import { ArrowUpRight } from "lucide-svelte";

  import FadeLeftRight from "$lib/ui/marketing/landing/FadeLeftRight.svelte";
  import HorizontalAutoScroll from "$lib/ui/marketing/landing/HorizontalAutoScroll.svelte";

  export let color: "black" | "dark-gray";

  const useCaseLinks = [
    {
      title: "Language Models",
      url: "/use-cases/language-models",
    },
    {
      title: "Image, Video, 3D",
      url: "/use-cases/image-video-3d",
    },
    {
      title: "Audio Processing",
      url: "/use-cases/audio",
    },
    {
      title: "Fine-Tuning",
      url: "/use-cases/fine-tuning",
    },
    {
      title: "Batch Processing",
      url: "/use-cases/job-queues",
    },
    {
      title: "Sandboxed Code",
      url: "/use-cases/sandboxes",
    },
    {
      title: "Computational Bio",
      url: "/use-cases/comp-bio",
    },
  ];
</script>

<FadeLeftRight {color}>
  <div class="flex flex-col gap-5">
    <HorizontalAutoScroll duration="60s" gap="24px">
      {#each useCaseLinks as item, i}
        <a
          class="px-2 py-1.5 md:px-5 md:py-3 border border-light-green/30 rounded-full text-sm md:marketing-h4 flex gap-5 items-center hover:bg-light-green/5"
          href={item.url}
        >
          <p class="pl-1">{item.title}</p>
          <span
            class="inline-block w-7 h-7 p-1.5 rounded-full"
            class:bg-brand-green={i % 3 === 0}
            class:bg-muted-yellow={i % 3 === 1}
            class:bg-bright-blue={i % 3 === 2}
            ><ArrowUpRight class="w-full h-full stroke-black" /></span
          >
        </a>
      {/each}
    </HorizontalAutoScroll>
  </div>
</FadeLeftRight>
